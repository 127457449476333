exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-template-tsx": () => import("./../../../src/pages/page-template.tsx" /* webpackChunkName: "component---src-pages-page-template-tsx" */),
  "component---src-templates-booking-tsx": () => import("./../../../src/templates/booking.tsx" /* webpackChunkName: "component---src-templates-booking-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-htmlpage-tsx": () => import("./../../../src/templates/htmlpage.tsx" /* webpackChunkName: "component---src-templates-htmlpage-tsx" */),
  "component---src-templates-komittee-detail-tsx": () => import("./../../../src/templates/komitteeDetail.tsx" /* webpackChunkName: "component---src-templates-komittee-detail-tsx" */),
  "component---src-templates-pinboard-tsx": () => import("./../../../src/templates/pinboard.tsx" /* webpackChunkName: "component---src-templates-pinboard-tsx" */),
  "component---src-templates-pinboardpage-tsx": () => import("./../../../src/templates/pinboardpage.tsx" /* webpackChunkName: "component---src-templates-pinboardpage-tsx" */),
  "component---src-templates-publications-tsx": () => import("./../../../src/templates/publications.tsx" /* webpackChunkName: "component---src-templates-publications-tsx" */),
  "component---src-templates-reservation-cancelled-tsx": () => import("./../../../src/templates/reservationCancelled.tsx" /* webpackChunkName: "component---src-templates-reservation-cancelled-tsx" */),
  "component---src-templates-reservation-completed-tsx": () => import("./../../../src/templates/reservationCompleted.tsx" /* webpackChunkName: "component---src-templates-reservation-completed-tsx" */),
  "component---src-templates-reservation-failed-tsx": () => import("./../../../src/templates/reservationFailed.tsx" /* webpackChunkName: "component---src-templates-reservation-failed-tsx" */),
  "component---src-templates-reservation-success-tsx": () => import("./../../../src/templates/reservationSuccess.tsx" /* webpackChunkName: "component---src-templates-reservation-success-tsx" */),
  "component---src-templates-reservation-tsx": () => import("./../../../src/templates/reservation.tsx" /* webpackChunkName: "component---src-templates-reservation-tsx" */)
}

